import { Grid, Typography } from "@mui/material";
import Image from "next/image";
import Flag from "../../../public/assets/images/home/Flag.webp";
import StatsBorderTop from "../../../public/assets/images/home/StatsBorderTop.webp";
import StatsOrangeBand from "../../../public/assets/images/home/StatsOrangeBand.webp";
import StatsGreenBand from "../../../public/assets/images/home/StatsGreenBand.webp";
import StatsBgImg from "../../../public/assets/images/home/image-135.webp";
import { NUMBER_OF_DOWNLOADS } from "../../../utils/Constants";

const Stats: React.FC = () => {
  return (
    <Grid
      container
      sx={{
        width: "100vw",
        justifyContent: { xs: "end", md: "space-between" },
        flexDirection: "column",
        position: "relative",
        alignItems: "center",
        padding: { xs: "50px 0 0 0", md: "60px 0 0 0" },
        minHeight: { xs: "50vh", md: "fit-content" },
      }}
    >
      <Grid
        sx={{
          height: { xs: "32px", md: "48px" },
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
        }}
      >
        <Image
          src={StatsBorderTop}
          alt="borderDesign"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          loading="lazy"
        />
      </Grid>
      <Grid
        sx={{
          width: "100%",
          margin: "auto",
          display: "block",
          padding: { xs: "12px 0 16px 0", md: "24px 0 16px 0" },
        }}
      >
        <Grid
          sx={{
            display: { xs: "flex", md: "flex" },
            justifyContent: { xs: "", md: "center" },
            alignItems: "center",
            paddingBottom: { xs: "", md: "24px" },
          }}
        >
          <Typography
            variant="body1"
            fontWeight="bold"
            textAlign="center"
            color="#6C717F"
            textTransform="capitalize"
            sx={{
              fontSize: { xs: "16px", md: "20px" },
              paddingLeft: { xs: "70px", md: "0" },
            }}
          >
            Made for India, Made in India
            <Image
              src={Flag}
              alt="flagIcon"
              style={{
                height: "42px",
                width: "42px",
                marginLeft: "4px",
              }}
              loading="lazy"
            />
          </Typography>
        </Grid>

        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Grid
            sx={{
              width: { xs: "20%", md: "360px" },
              height: { xs: "16px", md: "fit-content" },
            }}
          >
            <Image
              src={StatsOrangeBand}
              alt="orangeBand"
              // width={720}
              // height={52}
              // style={{ width: "720px", height: "52px" }}
              loading="lazy"
            />
          </Grid>
          <Typography
        
            textAlign="center"
            fontWeight="bold"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: { xs: "10px", md: "0" },
              fontSize: { xs: "40px", md: "64px" },
              zIndex: 2,
              whiteSpace: "nowrap",
            }}
          >
            {NUMBER_OF_DOWNLOADS + " " + "Lakh+"}
          </Typography>
          <Grid
            sx={{
              width: { xs: "20%", md: "360px" },
              height: { xs: "16px", md: "fit-content" },
            }}
          >
            <Image
              src={StatsGreenBand}
              alt="greenBand"
              // width={720}
              // height={52}
              // style={{ width: "720px", height: "52px" }}
              loading="lazy"
            />
          </Grid>
        </Grid>
        <Typography
        
          textAlign="center"
          fontWeight="700"
          sx={{
            textShadow: "0px 0px 4px white",
            fontSize: { xs: "18px", md: "32px" },
            width: { xs: "70%", md: "fit-content" },
            margin: "auto",
            paddingBottom: { xs: "12px", md: "40px" },
          }}
        >
          Partners have earned ₹100 Crores till now!
        </Typography>
      </Grid>
      <Image
        src={StatsBgImg}
        alt="Partners have earned ₹100 Crores till now!"
        style={{ width: "100%", objectFit: "contain" }}
        loading="eager"
        priority
      
      />
    </Grid>
  );
};

export default Stats;
